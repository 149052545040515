export default [
  {
    title: 'System Admin',
    type: 'admin',
    children: [
      {
        title: 'Admin Dashboard',
        route: 'admin-dashboard',
        icon: 'BarChart2Icon',
        resource: 'global-systemAdminDashboard',
        action: 'manage',
        type: 'admin',
      },
      {
        title: 'Admin Mobile Users',
        route: 'mobile-users-admin-list',
        icon: 'UserIcon',
        resource: 'global-users',
        action: 'manage-all',
        type: 'admin',
      },
      {
        title: 'Mobile Update',
        route: 'add-mobile-update',
        icon: 'PhoneIcon',
        resource: 'global-mobileUpdates',
        action: 'control',
        type: 'admin',
      },
      {
        title: 'Entity Reports',
        route: 'entity-reports',
        resource: 'global-EntitiesController',
        action: 'show',
        type: 'admin',
      },
      {
        title: 'Entities',
        type: 'admin',
        children: [
          {
            title: 'Islamic Orgs',
            route: 'inpos-list',
            resource: 'global-EntitiesController',
            action: 'show',
            type: 'admin',
          },
          {
            title: 'Commercial',
            route: 'business-list',
            icon: 'ShoppingBagIcon',
            resource: 'global-EntitiesController',
            action: 'show',
            type: 'admin',
          },
          {
            title: 'Event Organizer',
            route: 'event-organizers-list',
            icon: 'UserIcon',
            resource: 'global-EntitiesController',
            action: 'show',
            type: 'admin',
          },
        ],
      },

      {
        title: 'Permissions',
        icon: 'KeyIcon',
        type: 'admin',
        children: [
          {
            title: 'Staff',
            route: 'admin-users-list',
            resource: 'global-permissions',
            action: 'manage-permissions',
          },
          {
            title: 'Roles',
            route: 'admin-roles-list',
            resource: 'global-permissions',
            action: 'manage-permissions',
          },
        ],
      },
      {
        title: 'Notifications',
        route: 'list-notifications',
        icon: 'BellIcon',
        resource: 'global-adminNotifications',
        action: 'show',
        type: 'admin',
      },
      {
        title: 'Live',
        icon: 'VideoIcon',
        type: 'admin',
        children: [
          {
            title: 'Live Management',
            route: 'live-management-list',
            icon: 'VideoIcon',
            resource: 'global-LiveController',
            action: 'show',
            type: 'admin',
          },
        ],
      },
      {
        title: 'Announcements',
        route: 'admin-announcements-list',
        icon: 'MicIcon',
        resource: 'global-ActivityController',
        action: 'show',
        type: 'admin',
      }, {
        title: 'Reclaims',
        route: 'reclaims-list',
        icon: 'DollarSignIcon',
        resource: 'global-CommunityOfferController',
        action: 'show',
        type: 'admin',
      },
      {
        title: 'Community Events',
        icon: 'MicIcon',
        type: 'admin',
        children: [
          {
            title: 'Community Events',
            route: 'community-events-list',
            icon: 'MicIcon',
            resource: 'global-ScrappedEventController',
            action: 'show',
            type: 'admin',
          },
          {
            title: 'Suggested Events',
            route: 'suggested-events-list',
            icon: 'MicIcon',
            resource: 'global-ScrappedEventController',
            action: 'show',
            type: 'admin',
          },
        ],
      },
      {
        title: 'Community Offers',
        icon: 'DollarSignIcon',
        type: 'admin',
        children: [
          {
            title: 'Community Offers',
            route: 'offers-list',
            icon: 'DollarSignIcon',
            resource: 'global-CommunityOfferController',
            action: 'show',
            type: 'admin',
          },
          {
            title: 'Suggested Offers',
            route: 'suggested-offers-list',
            icon: 'DollarSignIcon',
            resource: 'global-ScrappedEventController',
            action: 'show',
            type: 'admin',
          },
        ],
      },
      {
        title: 'Orgs Suggestions',
        type: 'admin',
        children: [
          {
            title: 'Islamic Orgs',
            route: 'islamic-suggestions-list',
            icon: 'DollarSignIcon',
            resource: 'global-EntitiesController',
            action: 'show',
            type: 'admin',
          },
          {
            title: 'Business Orgs',
            route: 'business-suggestions-list',
            icon: 'DollarSignIcon',
            resource: 'global-EntitiesController',
            action: 'show',
            type: 'admin',
          },
        ],
      },
      {
        title: 'App Content',
        icon: 'TvIcon',
        type: 'admin',
        children: [
          {
            title: 'Azkar',
            route: 'azkar-list',
            resource: 'global-AzkarController',
            action: 'show',
          },
          {
            title: 'Azkar categories',
            route: 'azkarCategory-list',
            resource: 'global-AzkarCategoryController',
            action: 'show',
          },
          {
            title: 'MuslimDo Tutorial',
            route: 'tv-tutorial',
            resource: 'global-TVExplanation',
            action: 'update',
          },
          {
            title: 'Fundraising Ads',
            route: 'fundraising-ads',
            resource: 'global-AdsSliderController',
            action: 'store',
          },
          {
            title: 'Event Ads',
            route: 'event-ads',
            resource: 'global-AdsSliderController',
            action: 'store',
          },
          {
            title: 'Offer Ads',
            route: 'offer-ads',
            resource: 'global-AdsSliderController',
            action: 'store',
          },
        ],
      },
      {
        title: 'General Settings',
        icon: 'SettingsIcon',
        type: 'admin',
        children: [
          {
            title: 'Tags',
            route: 'tags',
            resource: 'global-TagController',
            action: 'store',
          },
          {
            title: 'Offer Tags',
            route: 'offer-tags',
            resource: 'global-TagController',
            action: 'store',
          },
          {
            title: 'Offer Categories',
            route: 'offer-categories',
            resource: 'global-TagController',
            action: 'store',
          },
          {
            title: 'Products',
            route: 'products',
            resource: 'global-TagController',
            action: 'store',
          },
          {
            title: 'Programs',
            route: 'programs',
            resource: 'global-TagController',
            action: 'store',
          },
          {
            title: 'Facilities',
            route: 'facilities',
            resource: 'global-FacilityController',
            action: 'store',
          },
          {
            title: 'Services',
            route: 'services',
            resource: 'global-ServiceController',
            action: 'store',
          },
          {
            title: 'Languages',
            route: 'languages',
            resource: 'global-LanguageController',
            action: 'store',
          },
          {
            title: 'Classifications',
            route: 'classifications',
            resource: 'global-ClassificationController',
            action: 'store',
          },
          {
            title: 'Sub Classifications',
            route: 'sub-classifications',
            resource: 'global-SubclassificationController',
            action: 'store',
          },
          {
            title: 'Donation Categories',
            route: 'donation-categories',
            resource: 'global-DonationCategoryController',
            action: 'store',
          },
          {
            title: 'Event Categories',
            route: 'event-categories',
            resource: 'global-EventTypeController',
            action: 'store',
          },
          {
            title: 'Budget Items',
            route: 'budget-items',
            resource: 'global-BudgetItemController',
            action: 'store',
          },
        ],
      },
    ],
  },
  {
    title: 'Entity Admin',
    children: [
      {
        title: 'Dashboard',
        icon: 'BarChart2Icon',
        route: 'inpo-dashboard',
        resource: 'adminDashboard',
        action: 'manage',
      },
      {
        title: 'Profile',
        route: 'entity-profile',
        resource: 'EntitiesController',
        action: 'show',
      },
      {
        title: 'Payment Details',
        icon: 'CreditCardIcon',
        route: 'payment-details-edit',
        resource: 'EntityPaymentController',
        action: 'updatePaymentDetails',
      },
      {
        title: 'Org Notifications',
        route: 'organizations-notifications-list',
        icon: 'BellIcon',
        resource: 'NotificationController',
        action: 'showEntityNotifications',
      },
      {
        title: 'Permissions',
        icon: 'KeyIcon',
        children: [
          {
            title: 'Staff',
            route: 'users-list',
            resource: 'permissions',
            action: 'manage-permissions',
          },
          {
            title: 'Roles',
            route: 'roles-list',
            resource: 'permissions',
            action: 'manage-permissions',
          },
        ],
      },
      {
        title: 'Followers Management',
        icon: 'UserIcon',
        children: [
          {
            title: 'List',
            route: 'mobile-users-list',
            icon: 'UserIcon',
            resource: 'UsersController',
            action: 'show',
          },
          {
            title: 'OTP',
            route: 'mobile-users-otp',
            icon: 'KeyIcon',
            resource: 'UsersController',
            action: 'getUserDetailsWithOTP',
          },
        ],
      },
      {
        title: 'Prayer Management',
        icon: 'MoonIcon',
        children: [
          {
            title: 'All Prayers',
            route: 'prayers-time-list',
            resource: 'EntityPrayer',
            action: 'show',
          },
          {
            title: 'Update Iqama',
            route: 'update-iqama',
            resource: 'EntityPrayer',
            action: 'modify',
          },
          {
            title: 'Get Azan',
            route: 'get-azan',
            resource: 'EntityPrayer',
            action: 'show',
          },
          {
            title: 'Import',
            route: 'import',
            resource: 'EntityPrayer',
            action: 'export',
          },
          {
            title: 'Export',
            route: 'export',
            resource: 'EntityPrayer',
            action: 'export',
          },
          {
            title: 'Country Settings',
            route: 'country-settings-list',
            resource: 'prayers',
            action: 'update-settings',
          },
        ],
      },
      {
        title: 'Prayer Booking',
        icon: 'MoonIcon',
        children: [
          {
            title: 'Prayer Events',
            route: 'prayer-bookings-list',
            icon: 'MoonIcon',
            resource: 'PrayerBookingController',
            action: 'show',
          },
          {
            title: 'Approval Requests',
            icon: 'CalendarIcon',
            route: 'booking-requests',
            resource: 'BookingActionController',
            action: 'show',
          },
          {
            title: 'Scan QR',
            icon: 'ApertureIcon',
            route: 'scan-qr',
            resource: 'PrayerBookingController',
            action: 'getBookingDetailsByQRCode',
          },
        ],
      },
      {
        title: 'Organization Events',
        route: 'reclaimed-events-list',
        icon: 'MicIcon',
        resource: 'ScrappedEventController',
        action: 'show',
      },
      {
        title: 'Event Management',
        icon: 'MoonIcon',
        children: [
          {
            title: 'Events',
            route: 'quick-events-list',
            icon: 'MoonIcon',
            resource: 'EventController',
            action: 'show',
          },
          {
            title: 'Booking Requests',
            route: 'quick-event-bookings-list',
            icon: 'MoonIcon',
            resource: 'EventSessionController',
            action: 'show',
          },
          {
            title: 'Scan QR',
            route: 'quick-event-scan-ticket',
            icon: 'CameraIcon',
            resource: 'EventSessionController',
            action: 'show',
          },
        ],
      },
      {
        title: 'Business Offers',
        route: 'business-offers-list',
        icon: 'DollarSignIcon',
        resource: 'CommunityOfferController',
        action: 'show',
      },
      {
        title: 'Fundraising Management',
        route: 'fundraising-list',
        icon: 'DollarSignIcon',
        resource: 'FundraisingController',
        action: 'save',
      },
      {
        title: 'Donors Management',
        route: 'donations-list',
        icon: 'DollarSignIcon',
        resource: 'UsersController',
        action: 'getUserDonationForEntity',
      },
      {
        title: 'Transactions',
        route: 'transactions-list',
        icon: 'DollarSignIcon',
        resource: 'TransactionController',
        action: 'index',
      },
      {
        title: 'Announcements',
        route: 'announcements-list',
        icon: 'MicIcon',
        resource: 'ActivityController',
        action: 'show',
      },
    ],
  },
]
