<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="newNotifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-link
          class="ml-auto"
          @click="markAllAsRead"
        >
          <small>Mark all as read</small>
        </b-link>
      </div>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-media
          :class="{'bg-grey': !notification.markedRead}"
        >
          <template #aside>
            <b-avatar
              size="32"
              variant="light-primary"
              :src="notificationAvatar(notification)"
              :text="avatarText(username(notification))"
              class="badge-minimal"
            />
          </template>
          <p class="media-heading d-flex justify-content-between">
            <span class="font-weight-bolder">
              {{ username(notification) }}
            </span>
            <span>{{ $moment.duration($moment(notification.createdAt).diff(Date(),'hours'),'hours').humanize(true) }}</span>
          </p>
          <div class="mb-1">
            <small>{{ notificationEmail(notification) }}</small>
          </div>
          <div class="d-flex justify-content-between">
            <small class="notification-text">{{ notification.permissionModule }}</small>
            <feather-icon
              v-if="!notification.markedRead"
              v-b-tooltip.hover
              icon="CircleIcon"
              title="Mark as read"
              @click="markNotificationAsRead(notification)"
            />
          </div>
          <div class="mb-1">
            <small class="notification-text">{{ notification.message }}</small>
          </div>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import notifications from '@/common/compositions/Notifications/NotificationsAPI'
import { onMessage } from 'firebase/messaging'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
    }
  },
  computed: {
    newNotifications() {
      return this.notifications.filter(notification => !notification.markedRead)
    },
  },
  setup() {
    const { getUserNotificationsList, markNotificationAsReadRequest, markAllAsReadRequest } = notifications()
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
      getUserNotificationsList,
      markNotificationAsReadRequest,
      markAllAsReadRequest,
    }
  },
  created() {
    this.getNotifications()
    onMessage(this.$messaging, () => {
      this.getNotifications()
    })
  },
  methods: {
    getNotifications() {
      this.getUserNotificationsList().then(res => {
        this.notifications = res.data.data
      })
    },
    markNotificationAsRead(notification) {
      this.markNotificationAsReadRequest(notification.id).then(() => {
        notification.markedRead = !notification.markedRead
      })
    },
    markAllAsRead() {
      this.markAllAsReadRequest().then(() => {
        this.notifications = this.notifications.map(notification => ({ ...notification, markedRead: true }))
      })
    },
    username(notification) {
      return notification.actionOwner?.profile?.username || notification.username || notification.actionOwner?.username
    },
    notificationEmail(notification) {
      return notification.actionOwner?.email
    },
    notificationAvatar(notification) {
      return `${process.env.VUE_APP_ACTIVITIES_BASE_URL?.split('/api')[0]}${notification.actionOwner?.profile?.avatar}`
    },
    avatarText,
  },
}
</script>

<style>

</style>
